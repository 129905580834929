<script>
import NativeCampaignManagementNewView from './NativeCampaignManagementNewView'
import CoreApi from '@/api/core'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'NativeCampaignManagementEditView',
  extends: NativeCampaignManagementNewView,
  methods: {
    fetchImage (damImageId) {
      return CoreApi().get('/image/' + damImageId)
        .then((response) => {
          return response.data
        })
        .catch(error => console.error(error))
    },
    getNativeCampaign () {
      this.$store.dispatch('nativeCampaignManagement/fetchOne', this.$route.params.id)
        .then(async () => {
          if (!this.$store.getters['nativeCampaignManagement/error']) {
            this.nativeCampaign = this.$store.getters['nativeCampaignManagement/detail'].data
            // TODO: Refactor or remove once all campaigns are updated to the new data structure.
            if (!this.nativeCampaign.mainAlternative.cropUsages || this.nativeCampaign.mainAlternative.cropUsages.length === 0) {
              this.nativeCampaign.mainAlternative.cropUsages = [{
                selectedByAuthor: false,
                ratio: '',
                availableDimensionsCsv: ''
              }]
            }
            // TODO -----
            if (this.status === 'paused') {
              this.nativeCampaign.paused = true
            }
            this.loadBrandsForDetail()
            this.numberOfPreviouslyExistingAlternatives = this.nativeCampaign.alternatives?.length ?? 0
            if (this.$route.fullPath.includes('duplicate')) {
              delete this.nativeCampaign.campaignId
            }
            this.mainImage = await this.fetchImage(this.nativeCampaign.mainAlternative.damImageId)
            for (const alternative of this.nativeCampaign.alternatives) {
              this.alternativesImages.push(await this.fetchImage(alternative.damImageId))
            }
          } else {
            NotifyService.setErrorMessage(this.$store.getters['nativeCampaignManagement/error'])
          }
        })
    }
  },
  created () {
    this.getNativeCampaign()
    this.isDuplicate = false
  }
}
</script>
